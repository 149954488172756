import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import LoadingSpin from "../components/base/Loading";
import router from "../constant/router";

const Home = React.lazy(() => import("./home"));
const Contact = React.lazy(() => import("./contact"));
const PioneWallet = React.lazy(() => import("./pione-wallet"));
const PioneChain = React.lazy(() => import("./pione-chain"));
const PioneCoin = React.lazy(() => import("./pione-coin"));
const PioneNode = React.lazy(() => import("./pione-node"));
const PioneGame = React.lazy(() => import("./pione-game"));
const PioneMart = React.lazy(() => import("./pione-mart"));
const PioneSwap = React.lazy(() => import("./pione-swap"));
const PioneHouse = React.lazy(() => import("./pione-house"));
const PioneFarm = React.lazy(() => import("./pione-farm"));
const PioneBot = React.lazy(() => import("./pione-bot"));
const PioneAi = React.lazy(() => import("./pione-ai"));
const PioneCare = React.lazy(() => import("./pione-care"));
const PioneScan = React.lazy(() => import("./pione-scan"));
const PioneSmart = React.lazy(() => import("./pione-smart"));
const Expione = React.lazy(() => import("./expoine"));
const TermsConditions = React.lazy(() => import("./terms-conditions"));
const PrivacyPolicy = React.lazy(() => import("./privacy-policy"));

const NotFound = React.lazy(() => import("./not-found"));

export const RouteContainer = () => (
  <Suspense fallback={<LoadingSpin />}>
    <Routes>
      <Route path={router.privacy_policy} element={<PrivacyPolicy />} />
      <Route path={router.terms_conditions} element={<TermsConditions />} />
      <Route path={router.home} element={<Home />} />
      <Route path={router.contact} element={<Contact />} />
      <Route path={router.expione} element={<Expione />} />
      <Route path={router.pione_wallet} element={<PioneWallet />} />
      <Route path={router.pione_smart} element={<PioneSmart />} />
      <Route path={router.pione_scan} element={<PioneScan />} />
      <Route path={router.pione_care} element={<PioneCare />} />
      <Route path={router.pione_ai} element={<PioneAi />} />
      <Route path={router.pione_bot} element={<PioneBot />} />
      <Route path={router.pione_farm} element={<PioneFarm />} />
      <Route path={router.pione_house} element={<PioneHouse />} />
      <Route path={router.pione_swap} element={<PioneSwap />} />
      <Route path={router.pione_mart} element={<PioneMart />} />
      <Route path={router.pione_game} element={<PioneGame />} />
      <Route path={router.pione_node} element={<PioneNode />} />
      <Route path={router.pione_coin} element={<PioneCoin />} />
      <Route path={router.pione_chain} element={<PioneChain />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Suspense>
);
