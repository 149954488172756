const routes = {
  home: "/",
  contact: "contact",
  pione_wallet: "pione-wallet",
  pione_chain: "pione-chain",
  pione_coin: "pione-coin",
  pione_node: "pione-node",
  pione_game: "pione-game",
  pione_mart: "pione-mart",
  pione_swap: "pione-swap",
  pione_house: "pione-house",
  pione_farm: "pione-farm",
  pione_bot: "pione-bot",
  pione_ai: "pione-ai",
  pione_care: "pione-care",
  pione_scan: "pione-scan",
  pione_smart: "pione-smart",
  expione: "expione",
  terms_conditions: "terms-conditions",
  privacy_policy: "privacy-policy",
};

export default routes;
